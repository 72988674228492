<template>
  <spaces-list item-type="projects" item-type-single="project" />
</template>

<script>
import SpacesList from '@/views/collectives-list/SpacesList.vue';

export default {
  name: 'ProjectsList',
  components: { SpacesList },

};
</script>
